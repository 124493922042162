import React from "react";
import comingSoon from "../Assets/images/comingSoon.gif";

function Landing() {
  return (
    <section className="landing_section">
      <div className="box">
        <img src={comingSoon} alt="comming soon" loading="lazy" />
        <h2 className="coming_heading">Coming Soon</h2>
      </div>
    </section>
  );
}

export default Landing;
